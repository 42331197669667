// import React, { useEffect, useState } from "react"
// import axios from "axios"
// // import { instaOne, instaTwo, instaThree, instaFour } from "../utils/imageUpload"

// import styled from "styled-components"
// import { useInsta } from "../hooks/"

// const Instagram = props => {
//   const { posts } = useInsta()

//   if (!posts) {
//     return <div></div>
//   }

//   return (
//     <>
//       {posts.map((post, i) => {
//         return (
//           <WrapperImg key={i}>
//             <img src={post} alt="Savorly Instagram" />
//           </WrapperImg>
//         )
//       })}
//     </>
//   )
// }

// export default Instagram

import React, { useEffect, useState } from "react"
import axios from "axios"
import { instaOne, instaTwo, instaThree, instaFour } from "../utils/imageUpload"
import styled from "styled-components"

const Instagram = props => {
  const [posts, setPosts] = useState(null)
  const [instaPosts, setInstaPosts] = useState(null)
  const [defaultPics, setdefaultPics] = useState([
    instaOne,
    instaTwo,
    instaThree,
    instaFour,
  ])

  useEffect(() => {
    async function definePosts() {
      //   try {
      //     const { data } = await axios.get(
      //       `https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink&access_token=${process.env.GATSBY_INSTA_ACCESS_TOKEN}`
      //     )
      //     const withoutVideo = data.data.filter(
      //       el => !el.media_url.includes("video")
      //     )
      //     setInstaPosts(withoutVideo.slice(0, 4))
      //   } catch (error) {
      //     console.log("error:", error)
      setPosts(getRandomThree(defaultPics))
      //   }
    }

    definePosts()

    function getRandomThree(defaultPics) {
      const randomizedPics = [...defaultPics]
      while (randomizedPics.length !== 4) {
        const index = Math.floor(Math.random() * defaultPics.length)
        randomizedPics.splice(index, 1)
      }

      return randomizedPics
    }
    // setPosts(getRandomThree(defaultPics))

    //if error
  }, [])

  if (instaPosts) {
    return (
      <>
        {instaPosts.map((post, i) => {
          return (
            <WrapperImg key={i}>
              <a
                href={post.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <p className="caption">{post.caption}</p> */}
                <img src={post.media_url} alt="Savorly Instagram" />
              </a>
            </WrapperImg>
          )
        })}
      </>
    )
  }

  if (posts) {
    return (
      <>
        {posts.map((post, i) => {
          return (
            <WrapperImg key={i}>
              <img src={post} alt="Savorly Instagram" />
            </WrapperImg>
          )
        })}
      </>
    )
  }

  return <div></div>
}

export default Instagram

const WrapperImg = styled.div`
  // height: 400px;

  overflow: hidden;

  img {
    display: flex;
    margin: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
    transition: transform 3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`
