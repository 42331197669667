import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import axios from "axios"
import styled from "styled-components"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Instagram from "../template/Instagram"
import Image from "gatsby-image"
import {
  landing,
  landingText,
  infoLanding,
  storyLineOne,
  storyLineTwo,
  storyOne,
  storyTwo,
  storyThree,
  info1,
  info2,
  info3,
  info4,
  info5,
  info6,
  infoTitle,
  instaIcon,
} from "../utils/imageUpload"
import { useStore } from "../context/TestContext"

import BodyWrapper from "../components/BodyWrapper"

const SERVER = process.env.GATSBY_SERVER_URL

// function useForm(inital = {}) {
//   const [form, setForm] = useState(inital)

//   function handleChange(target) {
//     setForm({ ...form, [target.name]: target.value })
//   }

//   async function onSubmit(productId) {
//     const { data } = await axios.post(`${SERVER}/${productId}`, form)
//   }

//   return { form, handleChange, onSubmit }
// }

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "Landing.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const IndexPage = data => {
  useEffect(() => {
    function mailchimp() {
      window.dojoRequire(["mojo/signup-forms/Loader"], function (L) {
        L.start({
          baseUrl: "mc.us3.list-manage.com",
          uuid: "9961282d9058bcb3aa48fa812",
          lid: "edf0a6d901",
          uniqueMethods: true,
        })
      })
    }

    const mcScript1 = document.createElement("script")
    mcScript1.type = "text/javascript"
    mcScript1.src =
      "//downloads.mailchimp.com/js/signup-forms/popup/unique-methods/embed.js"
    mcScript1.dataset["dojoConfig"] = "usePlainJson: true, isDebug: false"
    mcScript1.onload = mailchimp

    document.body.appendChild(mcScript1)
  }, [])

  const addYoptoScript = () => {
    const yotpoScript = document.createElement("script")
    yotpoScript.type = "text/javascript"
    yotpoScript.async = true
    yotpoScript.id = "yotpo-reviews-carousel"
    yotpoScript.src = `//staticw2.yotpo.com/${process.env.GATSBY_YOTPO_APP_KEY}/widget.js`

    document.body.appendChild(yotpoScript)
  }

  useEffect(() => {
    addYoptoScript()
    if (typeof window !== "undefined" && typeof window.yotpo !== "undefined") {
      window.yotpo.refreshWidgets()
    }

    return () => {
      const yotpoScriptsArr = document.querySelectorAll(
        "#yotpo-reviews-carousel"
      )
      for (let i = 0; i < yotpoScriptsArr.length; i++) {
        const yotpoScript = yotpoScriptsArr[i]
        yotpoScript.parentNode.removeChild(yotpoScript)
      }
    }
  }, [])
  // const dara = useStore()

  // useEffect(() => {
  //   async function getComments() {
  //     try {
  //       const { data: allComments } = await axios.get(SERVER)
  //     } catch (error) {
  //       console.log("error here. Server not connected. We are aware.")
  //     }
  //   }

  //   getComments()
  // }, [])

  return (
    <>
      <Layout>
        <SEO title="Home" />
        <NewLanding>
          <Image
            className="background"
            fluid={data.data.fileName.childImageSharp.fluid}
          />
          <div className="child">
            <p>Taste What's New</p>
            <Link className="landingLink" to="/shop">
              <button>Shop Now</button>
            </Link>
          </div>
        </NewLanding>{" "}
        <BodyWrapper bg={"var(--beige)"}>
          <Story>
            <div>
              <h1>
                SKINNY & CHUBBY GREW UP IN BELGIUM WITH LOTS OF DELICIOUS
                EUROPEAN TREATS & PLENTY OF MISCHIEF.
              </h1>
              <img className="gif" src={storyOne} alt="" />
            </div>
            <img className="line" src={storyLineOne} alt="" />

            <div>
              <img className="gif" src={storyTwo} alt="" />
              <h1>
                EACH VISIT HOME THEY WOULD FILL THEIR SUITCASES WITH GOODIES FOR
                THEMSELVES AND FRIENDS.
              </h1>
            </div>
            <img className="line" src={storyLineTwo} alt="" />
            <div>
              <h1>
                THEY SET OUT ON YET ANOTHER ADVENTURE: BECOMING ENTREPRENEURS &
                STARTING BELGIAN BOYS TO MAKE THE WORLD SWEETER.
              </h1>
              <img className="gif" src={storyThree} alt="" />
            </div>
          </Story>
        </BodyWrapper>
        <Info>
          <div className="container">
            <img className="infoTitle" src={infoTitle} alt="" />
            <div className="icons">
              <img className="info-icon" src={info1} alt="" />
              <img className="info-icon" src={info2} alt="" />
              <img className="info-icon" src={info3} alt="" />
              <img className="info-icon" src={info4} alt="" />
              <img className="info-icon" src={info5} alt="" />
              <img className="info-icon" src={info6} alt="" />
            </div>
            <p className="info-ingredients">
              *select products only - not all product assortment.
            </p>
          </div>
        </Info>
        <Insta bg="var(--pink)">
          <div className="instagram-wrapper">
            <Instagram />
          </div>
          <div className="follow-div">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/belgianboys/"
            >
              <img width="30px" className="icon" src={instaIcon} alt="" />
              <h3>CHECK OUT WHAT'S NEW & GIVEAWAYS ON INSTAGRAM</h3>
            </a>
          </div>
        </Insta>
        <Reviews className="reviews-carousel">
          <div
            className="yotpo yotpo-reviews-carousel"
            data-background-color="transparent"
            data-mode="top_rated"
            data-type="product"
            data-count="9"
            data-show-bottomline="1"
            data-autoplay-enabled="1"
            data-autoplay-speed="3000"
            data-show-navigation="1"
          >
            &nbsp;
          </div>
        </Reviews>
      </Layout>
    </>
  )
}

const Reviews = styled.div`
  padding: 0 0 50px 0;

  a {
    pointer-events: none !important;
    cursor: default !important;
    text-decoration: none !important;
    color: black !important;
  }
  @media (max-width: 769px) {
    .yotpo-reviews-carousel {
      width: 100% !important;
      // margin: 0 !important;
      max-width: fit-content !important;
      .scroller {
        width: 100% !important;

        .single-carousel {
          margin-right: 14vw !important;
        }
      }
    }
  }
`
const Info = styled.div`
  width: 100vw;

  background-color: var(--pink);
  height: 80vh;
  // background-position: center; /* Center the image */
  background-image: url(${infoLanding});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  @media (min-width: 1055px) {
    background-size: cover;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .sonOfTheSonOfWrapper {
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
  }
  .info-ingredients {
    font-size: 0.9em;
  }

  .infoTitle {
    width: 30%;
  }
  .info-icon {
    height: 100px;
  }

  .icons {
    display: flex;
    justify-content: space-around;
  }

  p {
    color: white;
    font-weight: light;
    font-size: 20px;
  }
  @media (max-width: 1000px) {
    background-size: 100%;
  }

  @media (max-width: 850px) {
    .info-icon {
      height: 90px;
    }
  }

  @media (max-width: 769px) {
    height: 50vh;
  }

  .yotpo-display-wrapper carousel-display-wrapper {
    width: 100% !important;
    div {
      width: 100% !important;
      color: blue !important;
    }
  }
  @media (max-width: 650px) {
    background-size: 100%;
    .infoTitle {
      width: 50%;
    }
    .icons {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
      grid-gap: 20px;
      width: 60vw;
    }
    .info-icon {
      height: 80px;
    }
  }

  @media (max-width: 590px) {
    height: 40vh;
    .container {
      padding: 1rem 0;
    }
    .icons {
      margin-top: 1rem;
    }
  }

  @media (max-width: 450px) {
    height: 75vh;
    background-size: cover;
    .container {
      margin: 1rem auto;
    }

    .icons {
      grid-template-columns: repeat(auto-fit, 80px);
      column-gap: 20px;
      row-gap: 20px;
    }
    .infoTitle {
      width: 35%;
    }

    .info-icon {
      text-align: left;
    }
    p {
      width: 80vw;
      text-align: center;
      margin-top: 1rem;
    }
  }

  @media (max-width: 376px) {
    min-height: 75vh;
    .infoTitle {
      width: 50%;
    }
  }

  // background-color: var(--pink);
`
const Story = styled.div`
  width: 100vw;
  background-color: var(--beige);
  padding: 10vh 0;

  @media (min-width: 1500px) {
    width: 100%;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    // padding: 0 20px;

    @media (min-width: 1500px) {
      justify-content: space-between;
    }
  }

  h1 {
    color: var(--darkBlue);
    text-transform: uppercase;
    width: 45%;
    text-align: center;
    margin: 0;
    @media (max-width: 769px) {
      width: 45%;
      font-size: 1.5rem;
    }

    @media (max-width: 600px) {
      width: 40%;
      font-size: 1.2rem;
    }
    @media (max-width: 500px) {
      /* width: 40%; */
      font-size: 1.1rem;
    }
  }

  .gif {
    width: 50%;
    @media (max-width: 769px) {
      width: 50%;
    }
  }
  .line {
    width: 30%;
    margin: 0 auto;
    display: flex;

    @media (max-width: 520px) {
      padding: 20px;
      width: 50%;
    }
  }
`

const NewLanding = styled.main`
  height: 80vh;
  width: 100vw;
  background: var(--pink);
  background-image: ${props => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;

  .background {
    width: 100%;
    height: 100%;
  }
  .child {
    position: absolute;
    @media (min-width: 1900px) {
      font-size: 10rem;
    }
    margin-left: 10vw;
    width: 25vw;
    color: white;
    font-size: 7rem;
    line-height: 100%;
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    // @media (min-width: 1500px) {
    //   margin-left: 20vw;
    // }
    p {
      text-transform: uppercase;
    }

    button {
      padding: 20px 50px;
      text-transform: uppercase;
      color: var(--darkBlue);
      background-color: rgba(255, 255, 255, 0.5);
      border: 2px solid white;
      border-radius: 30px;
      font-size: 1.2rem;
      font-weight: bold;

      :hover {
        background-color: white;
      }
    }
  }

  @media (max-width: 769px) {
    height: 40vh;
    background-position-x: 65%;
    padding-bottom: 2rem;
    .child {
      font-size: 3rem;
      margin-left: 10vw;
      button {
        padding: 10px 25px;
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 500px) {
    background-position-x: 60%;
    height: 20vh;
    .child {
      font-size: 3.2rem;
      button {
        font-size: 1rem;
        padding: 8px 20px;
      }
    }
  }

  @media (max-width: 440px) {
    .child {
      font-size: 2.2rem;
      button {
        font-size: 0.8rem;
      }
    }
  }

  @media (max-width: 408px) {
    .child {
      p {
        margin-top: 25px;
        font-size: 1.6rem;
        line-height: 110%;
      }
      button {
        padding: 6px 15px;
        font-size: 0.6rem;
      }
    }
  }

  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    height: 40vh;

    background-size: contain;
  }
`

const Landing = styled.div`
  background-image: url(${landing});
  background-position: center;
  background-size: cover;
  height: 80vh;
  width: 100vw;
  // background-color: var(--pink);
  display: flex;
  align-items: center;

  .landingText {
    width: 40%;
  }

  .landingInfo {
    display: flex;
    justify-content: flex-start;
    height: 90%;

    div {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }

    a {
      text-align: center;
    }
  }

  button {
    padding: 20px 50px;
    text-transform: uppercase;
    color: var(--darkBlue);
    background-color: rgba(255, 255, 255, 0.5);
    border: 2px solid white;
    border-radius: 30px;
    font-size: 1.2rem;
    font-weight: bold;

    :hover {
      background-color: white;
    }
  }
`

const Insta = styled.div`
  background: var(--pink);

  .follow-div {
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 10px;
    }

    a {
      margin: 20px 10px;

      color: white;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      text-decoration: none;

      &:hover {
        color: var(--darkBlue);
        @media (max-width: 769px) {
          color: white;
        }
      }
    }
  }

  .instagram-wrapper {
    @media (min-width: 1024px) {
      height: 50vh;
    }

    @media (max-width: 678px) {
      --repeat: auto-fit;

      div:last-child {
        display: none;
      }
    }

    @media (min-width: 679px) and (max-width: 1024px) {
      --repeat: 2;
    }

    @media (min-width: 1025px) {
      --repeat: 4;
    }

    display: grid;
    grid-template-columns: repeat(
      var(--repeat, auto-fit),
      minmax(calc(250px * 1), 1fr)
    );
    grid-gap: 0px;
  }
`

export default IndexPage
